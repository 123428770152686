// eslint-disable-next-line no-unused-vars
export default defineNuxtRouteMiddleware(async (to, from) => {
    try {
        await $fetch('/api/current', {
            method: 'POST',
            headers: useRequestHeaders(['cookie']),
        });
    } catch (e) {
        return navigateTo('/login');
    }

    return undefined;
});
